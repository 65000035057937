<script setup lang="ts">
import Button from "@frontend/ui-kit/src/components/Base/Button.vue";
import Fieldset from "@frontend/ui-kit/src/components/Base/Fieldset.vue";
import TextInput from "@frontend/ui-kit/src/components/Base/TextInput.vue";
import { IdentifyType } from "~/types/user/enum";
import { VueRecaptcha } from 'vue-recaptcha';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";
import useVuelidate from "@vuelidate/core";
import { useI18nValidators } from "~/plugins/i18n/i18n-validators";
import { useAuthorization } from "~/composables/auth/useAuthorization";
import { jwtDecode } from "jwt-decode";

const { t } = useI18n();
const { required, minLength, maxLength } = useI18nValidators();
const notificationStore = useNotificationStore();
const { authorize } = useAuthorization();
const gtm = useGtm()

const login = ref<string>('');
const password = ref<string>('');
const recaptchaToken = ref<string>('');
const config = useRuntimeConfig();
const isErrorPassword = ref<boolean>(false);
const recaptchaRef = ref<VueRecaptcha>();

const emit = defineEmits(['logged']);

const rules = computed(() => ({
    username: { 
        required, 
        minLength: minLength(2), 
        maxLength: maxLength(40)
    },
    password: { 
        required, 
        minLength: minLength(2), 
        maxLength: maxLength(40)
    }
}));
const $v = useVuelidate(rules, { username: login, password: password });

const onAuthorize = async () => {
    isErrorPassword.value = false;

    const isFormCorrect = await $v.value.$validate();

    if(!isFormCorrect) {
        $v.value.$touch();
        return;
    }

    if(recaptchaToken.value.length === 0) {
        notificationStore.showErrorText(t, t('login.validations.requiredCaptcha'));
        return;
    }

    const data = {
        userName: login.value ?? '',
        password: password.value ?? '',
        identifyType: IdentifyType.Password,
        captchaToken: recaptchaToken.value
    } as ILoginData;

    const result = await authorize(data);
    if(!result.isError) {
        const tokenCookie = useCookie("token");
        tokenCookie.value = result.data;
        const json = jwtDecode(tokenCookie.value!);
        trackEvent("login_submit", "login_success", json.sub);

        emit('logged');
    } else {
        trackEvent("login_submit", "login_failed");
        isErrorPassword.value = true;
        recaptchaRef.value?.reset();
    }
};

const onCaptchaSuccess = (response: string) => {
    recaptchaToken.value = response;
};
const onCaptchaExpired = () => {
    recaptchaToken.value = '';
}

const isPasswordVisible = ref<boolean>(false);
const showPassword = ():void => {
    isPasswordVisible.value = !isPasswordVisible.value;
}

const trackEvent = (eventName: string, loginStatus?: string, userId?: string): void => {
    const eventData: Record<string, string> = {
        event: eventName,
        ...(userId && { user_id: userId }),
        ...(loginStatus && { login_status: loginStatus }),
    };

    gtm?.trackEvent(eventData);
}
</script>

<template>
    <div>
        <span class="title">{{ $t("login.title") }}</span>
        <br/>
        <span class="subtitle">{{ $t("login.subtitle") }}</span>
    </div>

    <div class="login-field">
        <Fieldset :title="$t('login.loginTitle')" :isError="$v.username.$errors.length > 0 || isErrorPassword">
            <TextInput
                v-model="login"
                :errors="$v.username.$errors"
            />
        </Fieldset>
    </div>

    <div class="login-field">
        <Fieldset :title="$t('login.passwordTitle')" :isError="$v.password.$errors.length > 0 || isErrorPassword">
            <TextInput
                v-model="password"
                :errors="$v.password.$errors"
                :type="isPasswordVisible ? 'text' : 'password'"
            >
                <template #append-inner>
                    <v-icon  class="icon" @click="showPassword">
                        {{ isPasswordVisible ? 'ri-eye-off-fill' : 'ri-eye-fill' }}
                    </v-icon>
                </template>
            </TextInput>
            <div 
                v-if="isErrorPassword"
                class="error-message">
                {{ $t('login.validations.invalidLoginPassword') }}
            </div>
        </Fieldset>
        <div class="login-btn">
            <VueRecaptcha
                ref="recaptchaRef"
                :sitekey="config.public.captchaSiteKey"
                :loadRecaptchaScript="true"
                @verify="onCaptchaSuccess"
                @expired="onCaptchaExpired"
            />

            <Button 
                class="button"
                @click="onAuthorize()"
            >
                {{ $t("forms.shared.phrases.continue") }}
            </Button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.title {
    color: $color-grey-950;

    font-size: 20px;
    font-family: $ff-bold;
}

.subtitle {
    color: $color-grey-400;

    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.2px;
    font-family: $ff-bold;
}
/** Ошибка в данных */
.error {
    .icon {
        padding-left: 15px;
        color: $error-color;
    }

    :deep(.v-field) {
        border-color: $error-color;

        .v-field__outline__start,
        .v-field__outline__end {
            border-color: $error-color;
        }
    }
}

.login-field {
    width: 100%;
}

.login-btn {
    padding-top: 10px;
}

.button {
    width: 100%;
}

.error-message {
    color: $error-color;

    font-size: 12px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.12px;
}
</style>
