<script setup lang="ts">
import Tabs from "@frontend/ui-kit/src/components/Base/Tabs.vue";
import SmsLogin from "~/components/login/SmsLogin.vue";
import PasswordLogin from "~/components/login/PasswordLogin.vue";
import { ROUTES } from "~/constants/routes";
import type { INumberKeyValueData } from "@frontend/ui-kit/src/types/keyValue"; 

// тип таба
enum TabType {
    Phone = 0,       // По телефону
    Login  = 1,      // По логину
}

const { t } = useI18n();
const route = useRoute();

useHead({ title: t("routes.login") });

const isLoading = ref(true);

const tab = ref<TabType>(TabType.Phone);
const tabs = ref<INumberKeyValueData[]>([]);

const props = defineProps({
    isAdmin: { type: Boolean, required: false, default: false }
});

onMounted(async () => {
    tabs.value = getTabs();
    isLoading.value = false;
});

const getTabs = () : INumberKeyValueData[] => {
    const array = [];
    if(props.isAdmin) {
        array.push({type: TabType.Login, name: t('login.loginTitle')});
        tab.value = TabType.Login;
    } else {
        array.push({ type: TabType.Phone, name: t('login.phoneTitle') });
        tab.value = TabType.Phone;
    }
    
    return array;
}

const redirect = async () : Promise<void> => {
    // Если в урле был параметр return_url, кидаем туда, если нет, то на главную
    const returnUrl = route.query.return_url;
    // Приводим returnUrl к строке, если это массив, берем первый элемент
    const urlToNavigate = Array.isArray(returnUrl) ? returnUrl[0] : returnUrl;
    await navigateTo(urlToNavigate || ROUTES.index);
}
</script>

<template>
    <div class="content">
        <v-skeleton-loader
            v-if="isLoading"
            color="grey50"
            class="loader"
            type="heading, list-item, text, heading, button"
        />

        <div v-else class="container">
            <div v-if="false">
                <span class="title">{{ $t("login.title") }}</span>
                <br/>
                <span class="subtitle">{{ $t("login.subtitle") }}</span>
            </div>
            <!-- Скрыл табы временно -->
            <div v-if="false" class="tabs">
                <Tabs
                    v-model="tab"
                    :items="tabs"
                />
            </div>
            <div v-if="tab == TabType.Phone" class="login-fields">
                <SmsLogin 
                    @logged="redirect"/>
            </div>
            
            <div v-if="tab == TabType.Login" class="login-fields">
                <PasswordLogin
                    @logged="redirect"/>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;

    @include responsive( 'tablet' ) {
        padding: 0;
    }
}

.loader {
    width: 480px;

    @include responsive( 'phone' ) {
        width: 100%;
    }
}

.container {
    display: flex;
    width: 480px;
    padding: 44px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;

    border-radius: 32px;
    background: $main-card-color;
    box-shadow: $box-shadow-02;

    @include responsive( 'phone' ) {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        width: 480px;
        width: 100%;
    }

    .title {
        color: $color-grey-950;
        line-height: 125%;
        letter-spacing: -0.2px;
        font-size: 20px;
        font-family: $ff-bold;
    }

    .subtitle {
        color: $color-grey-400;

        font-size: 20px;
        line-height: 125%;
        letter-spacing: -0.2px;
        font-family: $ff-bold;
    }
}

.login-fields {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
}

@media (max-width: $desktop) {
    .login-fields {
        width: 100%;
    }
}
</style>
